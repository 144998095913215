import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import truck from "./ic-truck-dark.svg";
import dilvery from "./delivery.png";
import order from "./order.png";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper/modules";
import { useLocation, useNavigate } from "react-router-dom";
import { variables } from "./Variables";
import Loader from "./Layout/Loader";

const Tracking = () => {
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const searchParamsRef = useRef(searchParams);
  const [searchParamsChanged, setSearchParamsChanged] = useState(false);

  const isTracked = useRef(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
  const navigate = useNavigate();
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingNumbers, setTrackingNumbers] = useState([]);

  const [orderTracking, setOrderTracking] = useState([]);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const TrackOrder = () => {
    let TrackingFilter = "";
    if (!trackingNumber.trim()) {
      return;
    }
    let array = [...trackingNumbers];
    if (!trackingNumbers.includes(trackingNumber)) {
      setTrackingNumbers((prevNumbers) => {
        const updatedNumbers = [...prevNumbers, trackingNumber];
        return updatedNumbers;
      });
      array.push(trackingNumber);
    }
    setTrackingNumber("");
    if (array.length > 0) {
      array.forEach((t) => {
        if (TrackingFilter === "") {
          TrackingFilter = "?trackingNumber=" + t;
        } else {
          TrackingFilter = TrackingFilter + "&trackingNumber=" + t;
        }
      });
      navigate("/shipment" + TrackingFilter);
    }
  };
  const RemoveTrackingNumber = (value) => {
    if (trackingNumbers.includes(value)) {
      const updatedNumbers = trackingNumbers.filter((num) => num !== value);
      setTrackingNumbers(updatedNumbers);
      let TrackingFilter = "";
      if (updatedNumbers.length > 0) {
        updatedNumbers.forEach((t) => {
          if (TrackingFilter === "") {
            TrackingFilter = "?trackingNumber=" + t;
          } else {
            TrackingFilter = TrackingFilter + "&trackingNumber=" + t;
          }
        });
        navigate("/shipment" + TrackingFilter);
      } else {
        navigate("/shipment");
      }
    }
  };
  useEffect(() => {
    if (location.pathname === "/" || location.pathname !== '/shipment') {
      navigate("/shipment");
    }
  }, [location.pathname, navigate]);
  useEffect(() => {
    setIsLoading(true)
    searchParamsRef.current = searchParams;
    setSearchParamsChanged((prev) => !prev);
  }, [searchParams]);
  const handleSearchParamsChange = async (values) => {
    let TrackingArray = [...values];
    let TrackingFilter = "";
    setTrackingNumbers(TrackingArray);
    TrackingArray.forEach((tracking) => {
      if (TrackingFilter === "") {
        TrackingFilter = "?trackingNumber=" + tracking;
      } else {
        TrackingFilter = TrackingFilter + "&trackingNumber=" + tracking;
      }
    });
    isTracked.current = false;
    let response = await fetch(
      variables.API_URL + "Tracking/Shipping" + TrackingFilter,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      if (result.Code === 0) {
        let array = [];
        result.Trackings.forEach((tracking) => {
          if (tracking.HasTrackingResult) {
            array.push({
              agency: tracking.agency,
              OrderNumber: tracking.OrderNumber,
              TrackingNumber: tracking.TrackingNumber,
              TrackingResult: tracking.TrackingResult.sort(
                (a, b) =>
                  new Date(a.UpdateDateTime) - new Date(b.UpdateDateTime)
              ),
            });
          }
        });
        setOrderTracking(array);
        isTracked.current = true;
      } else {
        isTracked.current = false;
      }
    } else {
      isTracked.current = false;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true)
    const currentSearchParams = searchParamsRef.current;
    if (currentSearchParams && currentSearchParams.has("trackingNumber")) {
      let key = currentSearchParams.getAll("trackingNumber");
      handleSearchParamsChange(key);
    }else{
      setIsLoading(false)
    }
  }, [searchParamsChanged]);
  return (
    <section className="sf_colsOut shy-section">
      <div id="T0B511A5B016_Col00" className="sf_colsIn shy-container">
        <div className="multi-shipment">
          <div className="multi-shipment-container">
            <section className="track-shipment">
              <div className="track-shipment-card">
                <form
                  className="form-group"
                  onSubmit={(e) => {
                    e.preventDefault();
                    TrackOrder();
                  }}
                  autoComplete="off"
                >
                  <input
                    className="form-control track-form-control"
                    id="TrackingCardsNumber"
                    placeholder="Type your tracking number"
                    shy-name="trackingCardsListPage"
                    pattern="[a-zA-Z0-9]*"
                    value={trackingNumber}
                    onChange={(e) => setTrackingNumber(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn-primary-red"
                    onClick={() => TrackOrder()}
                  >
                    {" "}
                    Track
                  </button>
                </form>
                <div
                  className="form-track-group input-group col-md-12"
                  id="divTrackingNumbers"
                >
                  {trackingNumbers.length > 0
                    ? trackingNumbers.map((t, index) => {
                        return (
                          <div className="div-search-numbers" key={index}>
                            <label className="label-search-numbers">{t}</label>{" "}
                            <span
                              className="shy-i-cross btn-search-numbers"
                              onClick={() => RemoveTrackingNumber(t)}
                            >
                              x
                            </span>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <Fragment>
                  {isMobile ? (
                    <div className="track-shipment-list">
                      <Swiper
                        spaceBetween={30}
                        pagination={{
                          clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                      >
                        {orderTracking &&
                        orderTracking.length > 0 &&
                        trackingNumbers.length > 0 ? (
                          orderTracking.map((track, index) => {
                            if (track.agency === 0 && track.TrackingResult) {
                              return (
                                <SwiperSlide key={index}>
                                  <div className="shipment-card">
                                    {track.agency === 0 ? (
                                      <span className="shipment-tag">
                                        {" "}
                                        Aramex{" "}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    <div className="shipment-info-container">
                                    <div className="shipment-info">
                                      <h6>Tracking Number</h6>
                                      <div className="shipment-num">
                                        <h5>{track.TrackingNumber}</h5>
                                        <img src={truck} alt="Icon" />
                                        
                                      </div>
                                      <h6>Order Number</h6>
                                        <div className="shipment-num">
                                          <h5>{track.OrderNumber}</h5>
                                          <img src={order} alt="Icon" />
                                        </div>
                                    </div>

                                      <div
                                        className="shipment-updates"
                                        id="dvLastUpdateId_2"
                                      >
                                        <span className="title">
                                          Latest Update{" "}
                                        </span>
                                        <p className="shipment-update-descp">
                                          {" "}
                                          {
                                            track.TrackingResult[
                                              track.TrackingResult.length - 1
                                            ].Comments
                                          }
                                        </p>
                                        <span className="shipment-update-datetime">
                                          {" "}
                                          {new Date(
                                            track.TrackingResult[
                                              track.TrackingResult.length - 1
                                            ].UpdateDateTime
                                          )
                                            .toLocaleString("en-US", {
                                              day: "2-digit",
                                              month: "short",
                                              year: "2-digit",
                                              hour: "numeric",
                                              minute: "numeric",
                                            })
                                            .replace(",", "")}
                                        </span>
                                      </div>
                                    </div>
                                    {/* <div className="shipment-track-points custom-points">
                                  <span>Origin</span>
                                  <div className="track"></div>
                                  <span>Destination</span>
                                </div> */}

                                    <div
                                      className={
                                        "shipment-progess-container " +
                                        (track.TrackingResult.some(
                                          (t) => t.UpdateCode === "SH012"
                                        )
                                          ? ""
                                          : "created")
                                      }
                                    >
                                      {track.TrackingResult.some(
                                        (t) => t.UpdateCode === "SH012"
                                      ) ? (
                                        <div className="shipment-progess-point start done shipment-track-info">
                                          {/* <span className="orgin-info">
                                        <span className="location-title d-block d-sm-none">
                                          Origin
                                        </span>
                                        <div className="location-info-wrap">
                                          <span className="country">
                                            Lebanon
                                          </span>
                                          <span className="city">Beirut</span>
                                        </div>
                                      </span> */}
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Created</span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="shipment-progess-point start current done shipment-track-info">
                                          {/* <span className="orgin-info">
                                        <span className="location-title d-block d-sm-none">
                                          Origin
                                        </span>
                                        <div className="location-info-wrap">
                                          <span className="country">
                                            Lebanon
                                          </span>
                                          <span className="city">Beirut</span>
                                        </div>
                                      </span> */}
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Created</span>
                                          </div>
                                        </div>
                                      )}

                                      {track.TrackingResult.some(
                                        (t) => t.UpdateCode === "SH012"
                                      ) ? (
                                        <div className="shipment-progess-point done shipment-track-info">
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Collected</span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="shipment-progess-point shipment-track-info">
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Collected</span>
                                          </div>
                                        </div>
                                      )}
                                      {track.TrackingResult.some(
                                        (t) => t.UpdateCode === "SH047"
                                      ) ? (
                                        <div className="shipment-progess-point done shipment-track-info">
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Departed</span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="shipment-progess-point shipment-track-info">
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Departed</span>
                                          </div>
                                        </div>
                                      )}
                                      {track.TrackingResult.some(
                                        (t) => t.UpdateCode === "SH047"
                                      ) ? (
                                        <div className="shipment-progess-point done shipment-track-info">
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>In transit</span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="shipment-progess-point shipment-track-info">
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>In transit</span>
                                          </div>
                                        </div>
                                      )}

                                      {track.TrackingResult.some(
                                        (t) => t.UpdateCode === "SH047"
                                      ) ? (
                                        <div className="shipment-progess-point done shipment-track-info">
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Arrived at destination</span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="shipment-progess-point shipment-track-info">
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Arrived at destination</span>
                                          </div>
                                        </div>
                                      )}

                                      {track.TrackingResult.some(
                                        (t) => t.UpdateCode === "SH003"
                                      ) ? (
                                        <div className="shipment-progess-point done shipment-track-info">
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Out for delivery</span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="shipment-progess-point shipment-track-info">
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Out for delivery</span>
                                          </div>
                                        </div>
                                      )}

                                      {track.TrackingResult.some(
                                        (t) => t.UpdateCode === "SH005"
                                      ) ? (
                                        <div className="shipment-progess-point end current done shipment-track-info">
                                          {/* <span className="dest-info">
                                        <span className="location-title d-block d-sm-none">
                                          Destination
                                        </span>
                                        <div className="location-info-wrap">
                                          <span className="country">
                                            Lebanon
                                          </span>
                                          <span className="city">Beirut</span>
                                        </div>
                                      </span> */}
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Delivered</span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="shipment-progess-point end shipment-track-info">
                                          {/* <span className="dest-info">
                                        <span className="location-title d-block d-sm-none">
                                          Destination
                                        </span>
                                        <div className="location-info-wrap">
                                          <span className="country">
                                            Lebanon
                                          </span>
                                          <span className="city">Beirut</span>
                                        </div>
                                      </span> */}
                                          <div className="track-check-info">
                                            <div className="track-check">
                                              <div></div>
                                            </div>
                                            <span>Delivered</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </SwiperSlide>
                              );
                            } else {
                              return (
                                <div className="shipment-card">
                                  <div class="empty-state">
                                    <div class="empty-state__content">
                                      <div class="empty-state__icon">
                                        <img src={dilvery} alt="" />
                                      </div>
                                      <div class="empty-state__message">
                                        We could not find any result
                                        <br />
                                        with current selection
                                      </div>
                                      {/* <div class="empty-state__help">
                               
                                </div> */}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })
                        ) : (
                          <div className="shipment-card">
                            <div class="empty-state">
                              <div class="empty-state__content">
                                <div class="empty-state__icon">
                                  <img src={dilvery} alt="" />
                                </div>
                                <div class="empty-state__message">
                                  We could not find any result
                                  <br />
                                  with current selection
                                </div>
                                {/* <div class="empty-state__help">
                     
                      </div> */}
                              </div>
                            </div>
                          </div>
                        )}
                      </Swiper>
                    </div>
                  ) : isTracked.current ? (
                    <div className="track-shipment-list">
                      {orderTracking &&
                      orderTracking.length > 0 &&
                      trackingNumbers.length > 0 ? (
                        orderTracking.map((track, index) => {
                          if (track.agency === 0 && track.TrackingResult) {
                            return (
                              <Fragment key={index}>
                                <div className="shipment-card">
                                  {track.agency === 0 ? (
                                    <span className="shipment-tag">
                                      {" "}
                                      Aramex{" "}
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  <div className="shipment-info-container">
                                    <div className="shipment-info">
                                      <h6>Tracking Number</h6>
                                      <div className="shipment-num">
                                        <h5>{track.TrackingNumber}</h5>
                                        <img src={truck} alt="Icon" />
                                        
                                      </div>
                                      <h6>Order Number</h6>
                                        <div className="shipment-num">
                                          <h5>{track.OrderNumber}</h5>
                                          <img src={order} alt="Icon" />
                                        </div>
                                    </div>
                                    

                                    <div
                                      className="shipment-updates"
                                      id="dvLastUpdateId_2"
                                    >
                                      <span className="title">
                                        Latest Update{" "}
                                      </span>
                                      <p className="shipment-update-descp">
                                        {" "}
                                        {
                                          track.TrackingResult[
                                            track.TrackingResult.length - 1
                                          ].Comments
                                        }
                                      </p>
                                      <span className="shipment-update-datetime">
                                        {" "}
                                        {new Date(
                                          track.TrackingResult[
                                            track.TrackingResult.length - 1
                                          ].UpdateDateTime
                                        )
                                          .toLocaleString("en-US", {
                                            day: "2-digit",
                                            month: "short",
                                            year: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                          })
                                          .replace(",", "")}
                                      </span>
                                    </div>
                                  </div>
                                  {/* <div className="shipment-track-points custom-points">
                                  <span>Origin</span>
                                  <div className="track"></div>
                                  <span>Destination</span>
                                </div> */}

                                  <div
                                    className={
                                      "shipment-progess-container " +
                                      (track.TrackingResult.some(
                                        (t) => t.UpdateCode === "SH012"
                                      )
                                        ? ""
                                        : "created")
                                    }
                                  >
                                    {track.TrackingResult.some(
                                      (t) => t.UpdateCode === "SH012"
                                    ) ? (
                                      <div className="shipment-progess-point start done shipment-track-info">
                                        {/* <span className="orgin-info">
                                        <span className="location-title d-block d-sm-none">
                                          Origin
                                        </span>
                                        <div className="location-info-wrap">
                                          <span className="country">
                                            Lebanon
                                          </span>
                                          <span className="city">Beirut</span>
                                        </div>
                                      </span> */}
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Created</span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="shipment-progess-point start current done shipment-track-info">
                                        {/* <span className="orgin-info">
                                        <span className="location-title d-block d-sm-none">
                                          Origin
                                        </span>
                                        <div className="location-info-wrap">
                                          <span className="country">
                                            Lebanon
                                          </span>
                                          <span className="city">Beirut</span>
                                        </div>
                                      </span> */}
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Created</span>
                                        </div>
                                      </div>
                                    )}

                                    {track.TrackingResult.some(
                                      (t) => t.UpdateCode === "SH012"
                                    ) ? (
                                      <div className="shipment-progess-point done shipment-track-info">
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Collected</span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="shipment-progess-point shipment-track-info">
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Collected</span>
                                        </div>
                                      </div>
                                    )}
                                    {track.TrackingResult.some(
                                      (t) => t.UpdateCode === "SH047"
                                    ) ? (
                                      <div className="shipment-progess-point done shipment-track-info">
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Departed</span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="shipment-progess-point shipment-track-info">
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Departed</span>
                                        </div>
                                      </div>
                                    )}
                                    {track.TrackingResult.some(
                                      (t) => t.UpdateCode === "SH047"
                                    ) ? (
                                      <div className="shipment-progess-point done shipment-track-info">
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>In transit</span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="shipment-progess-point shipment-track-info">
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>In transit</span>
                                        </div>
                                      </div>
                                    )}

                                    {track.TrackingResult.some(
                                      (t) => t.UpdateCode === "SH047"
                                    ) ? (
                                      <div className="shipment-progess-point done shipment-track-info">
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Arrived at destination</span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="shipment-progess-point shipment-track-info">
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Arrived at destination</span>
                                        </div>
                                      </div>
                                    )}

                                    {track.TrackingResult.some(
                                      (t) => t.UpdateCode === "SH003"
                                    ) ? (
                                      <div className="shipment-progess-point done shipment-track-info">
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Out for delivery</span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="shipment-progess-point shipment-track-info">
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Out for delivery</span>
                                        </div>
                                      </div>
                                    )}

                                    {track.TrackingResult.some(
                                      (t) => t.UpdateCode === "SH005"
                                    ) ? (
                                      <div className="shipment-progess-point end current done shipment-track-info">
                                        {/* <span className="dest-info">
                                        <span className="location-title d-block d-sm-none">
                                          Destination
                                        </span>
                                        <div className="location-info-wrap">
                                          <span className="country">
                                            Lebanon
                                          </span>
                                          <span className="city">Beirut</span>
                                        </div>
                                      </span> */}
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Delivered</span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="shipment-progess-point end shipment-track-info">
                                        {/* <span className="dest-info">
                                        <span className="location-title d-block d-sm-none">
                                          Destination
                                        </span>
                                        <div className="location-info-wrap">
                                          <span className="country">
                                            Lebanon
                                          </span>
                                          <span className="city">Beirut</span>
                                        </div>
                                      </span> */}
                                        <div className="track-check-info">
                                          <div className="track-check">
                                            <div></div>
                                          </div>
                                          <span>Delivered</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Fragment>
                            );
                          } else {
                            return (
                              <div className="shipment-card">
                                <div class="empty-state">
                                  <div class="empty-state__content">
                                    <div class="empty-state__icon">
                                      <img src={dilvery} alt="" />
                                    </div>
                                    <div class="empty-state__message">
                                      We could not find any result
                                      <br />
                                      with current selection
                                    </div>
                                    {/* <div class="empty-state__help">
                             
                              </div> */}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="shipment-card">
                          <div class="empty-state">
                            <div class="empty-state__content">
                              <div class="empty-state__icon">
                                <img src={dilvery} alt="" />
                              </div>
                              <div class="empty-state__message">
                                We could not find any result
                                <br />
                                with current selection
                              </div>
                              {/* <div class="empty-state__help">
                       
                        </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="shipment-card">
                      <div class="empty-state">
                        <div class="empty-state__content">
                          <div class="empty-state__icon">
                            <img src={dilvery} alt="" />
                          </div>
                          <div class="empty-state__message">
                            We could not find any result
                            <br />
                            with current selection
                          </div>
                          {/* <div class="empty-state__help">
                     
                      </div> */}
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tracking;
