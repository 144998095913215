import React from "react";

const Header = () => {
  return (
    <header>
      <img
        src="https://storagesheyaakatestwe01.blob.core.windows.net/container1/Uploads/Photos/N/Media/b5036f92-506d-4944-9ec2-e3d822e02464.png"
        alt="Logo"
        className="logo"
      />
    </header>
  );
};

export default Header;
